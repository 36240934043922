import { useState, Fragment, useEffect } from "react";
import { useCookies } from "react-cookie";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TabsHolder from "../SubComponents/TabsHolder";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PublishIcon from "@mui/icons-material/Publish";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PDFFile from "../../Assets/files/instructions_v2.pdf";
import CSVFile from "../../Assets/files/cpo_findevplug_sample_template.csv";
import Papa from "papaparse";

import ImportModal from "./ImportModal";
import GetPendingList from "../../../Functions/GetPendingList";

const LocationEVSETab = () => {
	const [cookies, setCookie] = useCookies("");
	const [IsImportModalVisible, setIsImportModalVisible] = useState(false);
	const [IsDataTableVisible, setDataTableVisible] = useState(true);
	const [PendingRequestData, setPendingRequestData] = useState([]);
	const [IsAlertOpen, setIsAlertOpen] = useState(false);
	const [data, setData] = useState([]);
	const CSVFile = "/cpo_findevplug_sample_template.csv"; // Your CSV file path

	const handleLoadCSV = () => {
		fetch(CSVFile)
			.then((response) => response.text())
			.then((csvText) => {
				Papa.parse(csvText, {
					header: true,
					skipEmptyLines: true,
					complete: (result) => {
						setData(result.data);
						openInNewTab(result.data);
					},
				});
			});
	};

	const openInNewTab = (parsedData) => {
		const newWindow = window.open("", "_blank");
		if (!newWindow) return;

		// Create HTML Table
		const tableHeaders = `<tr>${Object.keys(parsedData[0])
			.map((key) => `<th style="border: 1px solid black; padding: 8px;">${key}</th>`)
			.join("")}</tr>`;

		const tableRows = parsedData
			.map(
				(row) =>
					`<tr>${Object.values(row)
						.map((cell) => `<td style="border: 1px solid black; padding: 8px;">${cell}</td>`)
						.join("")}</tr>`
			)
			.join("");

		const tableHTML = `
      <html>
        <head>
          <title>CSV Data</title>
        </head>
        <body style="font-family: Arial, sans-serif;">
          <h2>CSV Data Table</h2>
          <table style="border-collapse: collapse; width: 100%; text-align: left;">
            ${tableHeaders}
            ${tableRows}
          </table>
        </body>
      </html>
    `;

		newWindow.document.write(tableHTML);
		newWindow.document.close();
	};

	const handleClick = () => {
		setIsAlertOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setIsAlertOpen(false);
	};

	const handleImportModalOpen = () => {
		setIsImportModalVisible(true);
	};

	const handleImportModalClose = () => {
		setIsImportModalVisible(false);
	};
	const reRenderTable = () => {
		handleClick();
		setDataTableVisible(false);
		setTimeout(() => {
			setDataTableVisible(true);
		}, 1000);
	};

	useEffect(() => {
		let request = GetPendingList(cookies, setCookie);
		request.then((res) => {
			if (res.status === 200) {
				setPendingRequestData(res.data[0]);
			}
			console.log(res);
		});
	}, []);

	useEffect(() => {
		console.log(PendingRequestData);
	}, [PendingRequestData]);

	return (
		<>
			{IsImportModalVisible && (
				<ImportModal
					onSubmitSuccess={reRenderTable}
					onCloseModal={handleImportModalClose}
					onIsImportModalOpen={IsImportModalVisible}
				/>
			)}
			<Snackbar
				open={IsAlertOpen}
				autoHideDuration={6000}
				onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity='success'
					variant='filled'
					sx={{ width: "100%" }}>
					Data imported successfully!
				</Alert>
			</Snackbar>
			<Box sx={{ padding: { lg: "16px 144px 16px 144px", xs: "16px" } }}>
				<Typography variant='h4'>EV Charger and Location</Typography>
				<Box sx={{ mt: 3 }}>
					<Typography
						variant='subtitle2'
						sx={{ fontSize: "16px" }}
						fontWeight={"bold"}>
						Important Note/s:
						<Box sx={{ p: 1 }}>
							<List
								sx={{
									listStyleType: "disc",
									pl: 2,
									"& .MuiListItem-root": {
										display: "list-item",
										padding: "unset",
									},
								}}>
								<ListItem>
									<Typography
										variant='caption'
										sx={{ fontSize: "16px" }}>
										How to add Location site and EVSE/ EV Chargers?&nbsp;
										<Link
											target='_blank'
											rel='noopener noreferrer'
											href={PDFFile}>
											See details
										</Link>
									</Typography>
								</ListItem>
								<ListItem>
									<Typography
										variant='caption'
										sx={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
										Example CSV File:&nbsp;
										<Link
											target='_blank'
											rel='noopener noreferrer'
											href={CSVFile}>
											Download
										</Link>
										&nbsp; |
										<Button
											variant='text'
											color='success'
											sx={{ textTransform: "unset" }}
											onClick={handleLoadCSV}>
											View
										</Button>
									</Typography>
								</ListItem>
							</List>
						</Box>
					</Typography>
				</Box>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: { lg: "row", xs: "column" },
						justifyContent: { lg: "flex-end", xs: "center" },
						alignItems: { lg: "flex-end", xs: "center" },
						gap: 1,
						marginBottom: "16px",
						padding: "16px",
					}}>
					<Button
						sx={{ width: "max-content", textTransform: "none", fontWeight: "bold" }}
						variant='contained'
						color='error'
						endIcon={<PublishIcon />}
						onClick={() => handleImportModalOpen()}
						aria-label='Import EVSEs and Location Sites'>
						IMPORT DATA
					</Button>
					<Button
						disabled
						sx={{ width: "max-content", textTransform: "none", fontWeight: "bold" }}
						variant='contained'
						color='primary'
						endIcon={<FileDownloadIcon />}
						aria-label='Export EVSEs and Location Sites'
						onClick={() => reRenderTable()}>
						EXPORT DATA
					</Button>
				</Box>
				{PendingRequestData !== "NO_PENDING_LOCATIONS_AND_EVSES" && (
					<Box>
						<Alert
							severity='warning'
							sx={{ width: "25%" }}>
							<AlertTitle>Pending Transaction/s</AlertTitle>
							<Box sx={{ px: 1, display: "flex" }}>
								<Box>
									<Typography
										variant='subtitle2'
										fontWeight={"bold"}>
										Location/s:&nbsp;({PendingRequestData.pending_locations});&nbsp;
									</Typography>
								</Box>
								<Box>
									<Typography
										variant='subtitle2'
										fontWeight={"bold"}>
										EVSE/s:&nbsp;({PendingRequestData.pending_evses})
									</Typography>
								</Box>
							</Box>
						</Alert>
					</Box>
				)}

				{IsDataTableVisible ? <TabsHolder /> : <Box></Box>}
			</Box>
		</>
	);
};

export default LocationEVSETab;
