import TokenCheck from "./TokenCheck";

const GetEVSEList = async (cookies, setCookie, limit, offset, orderBy, order) => {
	let res = await TokenCheck(cookies, setCookie);
	if (res.status !== undefined && res.status === "PASSED") {
		console.log("[ACCESS TOKEN]: ", res.access_token)
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append(
			"Authorization",
			"Bearer " + res.access_token
		);

		const graphql = JSON.stringify({
			query: `query Evses {\r\n    evses(limit: ${limit}, offset: ${offset}, order_by: "${orderBy}", order: "${order}") {\r\n        uid\r\n        evse_id\r\n        serial_number\r\n              status\r\n        cpo_location_id\r\n                date_created\r\n        locations {\r\n            id\r\n            cpo_owner_id\r\n            name\r\n            address\r\n            address_lat\r\n            address_lng\r\n            city\r\n            region\r\n            postal_code\r\n            country_code\r\n            images\r\n            publish\r\n            date_created\r\n            date_modified\r\n        }\r\n        connectors {\r\n            id\r\n            evse_uid\r\n            connector_id\r\n            standard\r\n            format\r\n            power_type\r\n            max_voltage\r\n            max_amperage\r\n            max_electric_power\r\n            connector_type\r\n            rate_setting\r\n            status\r\n            date_created\r\n            date_modified\r\n        }\r\n        capabilities {\r\n            id\r\n            code\r\n            description\r\n        }\r\n        payment_types {\r\n            id\r\n            code\r\n            description\r\n        }\r\n    }\r\n}\r\n`,
			variables: {}
		})

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: graphql,
			redirect: "follow",
		};

		let fetchData;

		try {
			let response = await fetch(
				`${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/graphql`,
				requestOptions
			);

			if (!response.ok) {
				// Handle specific error status codes
				if (response.status === 403) {
					console.log("Unauthorized: Token invalid.");
					console.log(response)
					// localStorage.clear();
					// window.location.reload()
				} else {
					console.log(`Server error: ${response.status}`);
				}
			}
			fetchData = response.json();
		} catch (error) {
			console.log("Network error or other unexpected issue:", error);
		}

		return fetchData;
	} else {
		// clearAllCookies();
	}

};

export default GetEVSEList;
