import { useState, useRef, useEffect, Fragment } from "react";

import { Box, Typography, ButtonGroup, Tooltip, Button, Chip } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const TokenAPIsTab = (props) => {
	const [ShowTokenVisible, setShowTokenVisible] = useState(true);
	const [BodyBtnText, setBodyBtnText] = useState("copy");
	const [Token, setToken] = useState("");
	const HooksURL1 = "https://stg-ocpi.sysnetph.com/ocpi/cpo/api/v1/webhook/locations/:country_code/:party_id";
	const HooksURL2 = "https://stg-ocpi.sysnetph.com/ocpi/cpo/api/v1/webhook/evse/:country_code/:party_id";
	const HooksURL3 =
		"https://stg-ocpi.sysnetph.com/ocpi/hub/locations/:country_abbr/:party_id/:location_id/:evse_uid/:connector_id";
	const HooksBody2 = `{
	"location_id": 176,
	"uid": "123456689",
	"status": "AVAILABLE",
	"meter_type": "AC",
	"kwh": 7,
	"connectors": [
		{
			"standard": "CHADEMO",
			"format": "SOCKET",
			"power_type": "AC",
			"max_voltage": 230,
			"max_amperage": 16,
			"max_electric_power": 120
		}
	],
	"capabilities": ["CREDIT_DEBIT_PAYABLE", "QR_READER"],
	"payment_types": ["GCASH", "MAYA"]
}
	`;
	const HooksBody3 = `{
    "name": "Pionner Highlands", // Location's name
    "address": "Pioneer Mandaluyong City", // Location's address
    "evses": [
        { // EVSE 1
            "uid": "123412341", // Serial number of EV Charger
            "status": "OFFLINE", // ['AVAILABLE','OFFLINE']
            "meter_type": "AC", // ['AC', 'DC']
            "kwh": 7, 
            "connectors": [
                { // Connector 1
                   "standard": "CHADEMO",
                   "format": "SOCKET",
                   "power_type": "AC",
                   "max_voltage": 230,
                   "max_amperage": 16,
                   "max_electric_power": 120
                },
                { // Connector 2
                   "standard": "CHADEMO",
                   "format": "SOCKET",
                   "power_type": "AC",
                   "max_voltage": 230,
                   "max_amperage": 16,
                   "max_electric_power": 120
                }
            ]
        },
        {} // EVSE 2 if possible
    ]
}`;
	const [TooltipText, setTooltipText] = useState("Click to Copy");
	const textRef = useRef(null);
	const hookTextRef1 = useRef(null);
	const hookTextRef2 = useRef(null);
	const hookTextRef3 = useRef(null);

	useEffect(() => {
		if (props.onTokenC !== undefined) {
			setToken(props.onTokenC);
		}
	}, [props]);

	const hookURLCopy1 = HooksURL1.split("/").map((part, index) => {
		if (part.startsWith(":")) {
			return (
				<span
					key={index}
					style={{ color: "blue" }}>
					{part}
				</span>
			);
		} else {
			return part;
		}
	});

	const hookURLCopy2 = HooksURL2.split("/").map((part, index) => {
		if (part.startsWith(":")) {
			return (
				<span
					key={index}
					style={{ color: "blue" }}>
					{part}
				</span>
			);
		} else {
			return part;
		}
	});

	const hookURLCopy3 = HooksURL3.split("/").map((part, index) => {
		if (part.startsWith(":")) {
			return (
				<span
					key={index}
					style={{ color: "blue" }}>
					{part}
				</span>
			);
		} else {
			return part;
		}
	});

	const handleMouseEnter = (type) => {
		const selection = window.getSelection();
		const range = document.createRange();
		range.selectNodeContents(
			type === "token"
				? textRef.current
				: type === "hook1"
				? hookTextRef1.current
				: type === "hook2"
				? hookTextRef2.current
				: hookTextRef3.current
		);
		selection.removeAllRanges();
		selection.addRange(range);
	};

	const handleMouseLeave = (type = undefined) => {
		const selection = window.getSelection();
		selection.removeAllRanges();
		setTooltipText("Click to Copy");
		if (type !== undefined) {
			if (type === "body") {
				setBodyBtnText("copy");
			}
		}
	};

	const handleClick = (type) => {
		navigator.clipboard
			.writeText(type === "token" ? Token : type === "hook1" ? HooksURL1 : type === "hook2" ? HooksURL2 : HooksURL3)
			.then(
				() => {
					if (type === "body") {
						setBodyBtnText("copied");
					}
					setTooltipText("Copied");
					console.log("Text copied to clipboard");
				},
				(err) => {
					console.error("Could not copy text: ", err);
				}
			);
	};

	const handleClickShowTokenVisible = () => setShowTokenVisible((show) => !show);

	const ActionCell = ({ params }) => {
		return (
			<Box sx={{ display: "flex", height: "100%", transform: "translate(0, -.5%)" }}>
				<ButtonGroup sx={{ height: "100%" }}>
					<Tooltip title='Show/Hide'>
						<Button
							variant='contained'
							sx={{ height: "48px", borderRadius: "unset" }}
							color='inherit'
							onClick={handleClickShowTokenVisible}>
							{ShowTokenVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
						</Button>
					</Tooltip>
					<Tooltip title={TooltipText}>
						<Button
							onMouseLeave={handleMouseLeave}
							onClick={() => handleClick("token")}
							variant='contained'
							sx={{ height: "48px", borderRadius: "unset" }}
							color='success'>
							<ContentCopyIcon />
						</Button>
					</Tooltip>
				</ButtonGroup>
			</Box>
		);
	};

	const maskString = (str) => {
		return str.replace(/./g, "*");
	};

	return (
		<Box sx={{ padding: { lg: "16px 144px 16px 144px", xs: "16px" }, width: "100%" }}>
			<Typography variant='h4'>Token and Webhooks</Typography>
			<Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
				<Box sx={{ width: "60%", display: "flex", alignItems: "center" }}>
					<Box
						sx={{
							backgroundColor: "#0C0348",
							height: "48px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "10%",
						}}>
						<Typography
							variant='subtitle2'
							sx={{ fontSize: "12px", whiteSpace: "nowrap", margin: "8px", color: "white" }}
							fontWeight={"bold"}>
							TOKEN_C
						</Typography>
					</Box>
					<Box
						sx={{
							padding: "8px",
							width: "80%",
							border: "solid 1px black",
							height: "100%",
							display: "flex",
							alignItems: "center",
						}}>
						<Tooltip
							title={TooltipText}
							placement='top-end'
							arrow>
							<Typography
								ref={textRef}
								onMouseEnter={() => handleMouseEnter("token")}
								onMouseLeave={handleMouseLeave}
								onClick={() => handleClick("token")}
								sx={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									fontSize: ShowTokenVisible && "18px",
									fontWeight: ShowTokenVisible && "bold",
									px: 1,
									cursor: "pointer",
									userSelect: "text",
									// textOverflow: "ellipsis",
								}}>
								{ShowTokenVisible ? maskString(Token) : Token}
							</Typography>
						</Tooltip>
					</Box>

					<Box sx={{ width: "10%" }}>
						<ActionCell />
					</Box>
				</Box>
			</Box>
			<Box sx={{ p: 5 }}>
				<Typography variant='h5'>Webhook APIs</Typography>
				<Box sx={{ p: 3 }}>
					<Accordion disabled>
						<AccordionSummary
							sx={{ display: "flex", alignItems: "center" }}
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1-content'
							id='panel1-header'>
							<Typography
								variant='h6'
								sx={{ width: "33%", flexShrink: 0 }}>
								1. Add Location (Not yet available)
							</Typography>
							{/* <Typography
								variant='subtitle2'
								sx={{ color: "gray" }}>
								This API updates a specific EVSE based on its serial number.
							</Typography> */}
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{ py: 3, px: 5, display: "flex", alignItems: "center", gap: 1 }}>
								<Chip
									variant='outlined'
									color='warning'
									label='POST'
								/>
								<Box>
									<Chip
										variant='outlined'
										sx={{ borderRadius: "unset" }}
										onMouseEnter={() => handleMouseEnter("hook1")}
										onMouseLeave={handleMouseLeave}
										onClick={() => handleClick("hook1")}
										label={
											<Tooltip
												title={TooltipText}
												placement='top-end'
												arrow>
												<Typography
													ref={hookTextRef1}
													sx={{ cursor: "pointer", userSelect: "text" }}>
													{hookURLCopy1.map((part, index) => (
														<Fragment key={index}>
															{part}
															{index < hookURLCopy1.length - 1 && "/"}
														</Fragment>
													))}
												</Typography>
											</Tooltip>
										}
									/>
									<Tooltip
										title={TooltipText}
										placement='top-end'
										arrow>
										<Button
											sx={{ borderRadius: "0 8px 8px 0" }}
											size='small'
											variant='contained'
											color='success'
											onClick={() => handleClick("hook1")}
											onMouseLeave={handleMouseLeave}>
											<ContentCopyIcon />
										</Button>
									</Tooltip>
								</Box>
							</Box>
							<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
								<Typography
									variant='subtitle2'
									fontWeight={"bold"}
									fontSize={"16px"}>
									Header/s
								</Typography>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Chip
										label={
											<Box sx={{ display: "flex", gap: 2 }}>
												<Typography
													variant='subtitle2'
													fontWeight={"bold"}>
													Authorization:&nbsp;
												</Typography>
												<Typography variant='subtitle2'>Bearer TOKEN_C</Typography>
											</Box>
										}
									/>
								</Box>
							</Box>
							<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
								<Typography
									variant='subtitle2'
									fontWeight={"bold"}
									fontSize={"16px"}>
									Request Parameters
								</Typography>
								<Box sx={{ marginLeft: "32px", marginTop: "16px", display: "flex", flexDirection: "column", gap: 1 }}>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														country_code&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>Location's country code.</Typography>
												</Box>
											</Box>
										}
									/>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														party_id&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>Location's associated party ID.</Typography>
												</Box>
											</Box>
										}
									/>
								</Box>
							</Box>
							<Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Typography
										variant='subtitle2'
										fontWeight={"bold"}
										fontSize={"16px"}>
										Sample Request
									</Typography>
									<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
										<Chip
											sx={{ height: "auto", width: "100%", justifyContent: "unset" }}
											label={
												<Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
													<Box
														sx={{
															pt: 3,
															pb: 2,
															px: 5,
															display: "flex",
															alignItems: "center",
															gap: 1,
															alignSelf: "center",
														}}></Box>
													<Box sx={{ px: 5, pb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
														<Typography
															variant='subtitle2'
															fontWeight={"bold"}
															fontSize={"16px"}>
															Request Body
														</Typography>
														<Chip
															sx={{ height: "auto", p: 2, justifyContent: "unset", backgroundColor: "white" }}
															variant='outlined'
															label={
																<Typography
																	variant='subtitle2'
																	component={"pre"}>
																	{`{
	location_id: 176,
	uid: "123456689",
	status: "AVAILABLE",
	meter_type: "AC",
	kwh: 7,
	connectors: [
		{
			standard: "CHADEMO",
			format: "SOCKET",
			power_type: "AC",
			max_voltage: 230,
			max_amperage: 16,
			max_electric_power: 120,
		},
	],
	capabilities: ["CREDIT_DEBIT_PAYABLE", "QR_READER"],
	payment_types: ["GCASH", "MAYA"],
}`}
																</Typography>
															}
														/>
													</Box>
												</Box>
											}
										/>
									</Box>
								</Box>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Typography
										variant='subtitle2'
										fontWeight={"bold"}
										fontSize={"16px"}>
										Sample Response
									</Typography>
									<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
										<Chip
											sx={{ height: "auto", width: "100%", justifyContent: "unset" }}
											label={
												<Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 5, pb: 2 }}>
													<Box sx={{ px: 5, pb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
														<Typography
															variant='subtitle2'
															fontWeight={"bold"}
															fontSize={"16px"}>
															Response Body
														</Typography>
														<Chip
															sx={{
																height: "auto",
																p: 2,
																justifyContent: "unset",
																backgroundColor: "white",
																width: "100%",
															}}
															variant='outlined'
															label={
																<Typography
																	variant='subtitle2'
																	component={"pre"}>
																	{`{
	"status": 200,
	"data": [
		{
			"location_id": 754
		}
	], // list of location id
	"message": "Success"
}

`}
																</Typography>
															}
														/>
													</Box>
												</Box>
											}
										/>
									</Box>
								</Box>
							</Box>
						</AccordionDetails>
					</Accordion>
					<Accordion disabled>
						<AccordionSummary
							sx={{ display: "flex", alignItems: "center" }}
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1-content'
							id='panel1-header'>
							<Typography
								variant='h6'
								sx={{ width: "33%", flexShrink: 0 }}>
								2. Add EVSE (Not yet available)
							</Typography>
							{/* <Typography
								variant='subtitle2'
								sx={{ color: "gray" }}>
								This API updates a specific EVSE based on its serial number.
							</Typography> */}
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{ py: 3, px: 5, display: "flex", alignItems: "center", gap: 1 }}>
								<Chip
									variant='outlined'
									color='warning'
									label='POST'
								/>
								<Box>
									<Chip
										variant='outlined'
										sx={{ borderRadius: "unset" }}
										onMouseEnter={() => handleMouseEnter("hook2")}
										onMouseLeave={handleMouseLeave}
										onClick={() => handleClick("hook2")}
										label={
											<Tooltip
												title={TooltipText}
												placement='top-end'
												arrow>
												<Typography
													ref={hookTextRef2}
													sx={{ cursor: "pointer", userSelect: "text" }}>
													{hookURLCopy2.map((part, index) => (
														<Fragment key={index}>
															{part}
															{index < hookURLCopy2.length - 1 && "/"}
														</Fragment>
													))}
												</Typography>
											</Tooltip>
										}
									/>
									<Tooltip
										title={TooltipText}
										placement='top-end'
										arrow>
										<Button
											sx={{ borderRadius: "0 8px 8px 0" }}
											size='small'
											variant='contained'
											color='success'
											onClick={() => handleClick("hook2")}
											onMouseLeave={handleMouseLeave}>
											<ContentCopyIcon />
										</Button>
									</Tooltip>
								</Box>
							</Box>
							<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
								<Typography
									variant='subtitle2'
									fontWeight={"bold"}
									fontSize={"16px"}>
									Header/s
								</Typography>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Chip
										label={
											<Box sx={{ display: "flex", gap: 2 }}>
												<Typography
													variant='subtitle2'
													fontWeight={"bold"}>
													Authorization:&nbsp;
												</Typography>
												<Typography variant='subtitle2'>Bearer TOKEN_C</Typography>
											</Box>
										}
									/>
								</Box>
							</Box>
							<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
								<Typography
									variant='subtitle2'
									fontWeight={"bold"}
									fontSize={"16px"}>
									Request Parameters
								</Typography>
								<Box sx={{ marginLeft: "32px", marginTop: "16px", display: "flex", flexDirection: "column", gap: 1 }}>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														country_code&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>Location's country code.</Typography>
												</Box>
											</Box>
										}
									/>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														party_id&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>Location's associated party ID.</Typography>
												</Box>
											</Box>
										}
									/>
								</Box>
							</Box>
							<Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Typography
										variant='subtitle2'
										fontWeight={"bold"}
										fontSize={"16px"}>
										Sample Request
									</Typography>
									<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
										<Chip
											sx={{ height: "auto", width: "100%", justifyContent: "unset" }}
											label={
												<Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
													<Box
														sx={{
															pt: 3,
															pb: 2,
															px: 5,
															display: "flex",
															alignItems: "center",
															gap: 1,
															alignSelf: "center",
														}}></Box>
													<Box sx={{ px: 5, pb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
														<Typography
															variant='subtitle2'
															fontWeight={"bold"}
															fontSize={"16px"}>
															Request Body
														</Typography>
														<Chip
															sx={{ height: "auto", p: 2, justifyContent: "unset", backgroundColor: "white" }}
															variant='outlined'
															label={
																<Typography
																	variant='subtitle2'
																	component={"pre"}>
																	{HooksBody2}
																</Typography>
															}
														/>
													</Box>
												</Box>
											}
										/>
									</Box>
								</Box>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Typography
										variant='subtitle2'
										fontWeight={"bold"}
										fontSize={"16px"}>
										Sample Response
									</Typography>
									<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
										<Chip
											sx={{ height: "auto", width: "100%", justifyContent: "unset" }}
											label={
												<Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 5, pb: 2 }}>
													<Box sx={{ px: 5, pb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
														<Typography
															variant='subtitle2'
															fontWeight={"bold"}
															fontSize={"16px"}>
															Response Body
														</Typography>
														<Chip
															sx={{
																height: "auto",
																p: 2,
																justifyContent: "unset",
																backgroundColor: "white",
																width: "100%",
															}}
															variant='outlined'
															label={
																<Typography
																	variant='subtitle2'
																	component={"pre"}>
																	{`{
	"status": 200,
	"data": "SUCCESS",
	"message": "Success"
}
`}
																</Typography>
															}
														/>
													</Box>
												</Box>
											}
										/>
									</Box>
								</Box>
							</Box>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							sx={{ display: "flex", alignItems: "center" }}
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1-content'
							id='panel1-header'>
							<Typography
								variant='h6'
								sx={{ width: "33%", flexShrink: 0 }}>
								3. Update EVSE Status
							</Typography>
							<Typography
								variant='subtitle2'
								sx={{ color: "gray" }}>
								This API updates a specific EVSE based on its serial number.
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{ py: 3, px: 5, display: "flex", alignItems: "center", gap: 1 }}>
								<Chip
									variant='outlined'
									color='secondary'
									label='PATCH'
								/>
								<Box>
									<Chip
										variant='outlined'
										sx={{ borderRadius: "unset" }}
										onMouseEnter={() => handleMouseEnter("hook3")}
										onMouseLeave={handleMouseLeave}
										onClick={() => handleClick("hook3")}
										label={
											<Tooltip
												title={TooltipText}
												placement='top-end'
												arrow>
												<Typography
													ref={hookTextRef3}
													sx={{ cursor: "pointer", userSelect: "text" }}>
													{hookURLCopy3.map((part, index) => (
														<Fragment key={index}>
															{part}
															{index < hookURLCopy3.length - 1 && "/"}
														</Fragment>
													))}
												</Typography>
											</Tooltip>
										}
									/>
									<Tooltip
										title={TooltipText}
										placement='top-end'
										arrow>
										<Button
											sx={{ borderRadius: "0 8px 8px 0" }}
											size='small'
											variant='contained'
											color='success'
											onClick={() => handleClick("hook3")}
											onMouseLeave={handleMouseLeave}>
											<ContentCopyIcon />
										</Button>
									</Tooltip>
								</Box>
							</Box>
							<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
								<Typography
									variant='subtitle2'
									fontWeight={"bold"}
									fontSize={"16px"}>
									Header/s
								</Typography>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Chip
										label={
											<Box sx={{ display: "flex", gap: 2 }}>
												<Typography
													variant='subtitle2'
													fontWeight={"bold"}>
													Authorization:&nbsp;
												</Typography>
												<Typography variant='subtitle2'>Bearer TOKEN_C</Typography>
											</Box>
										}
									/>
								</Box>
							</Box>
							<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
								<Typography
									variant='subtitle2'
									fontWeight={"bold"}
									fontSize={"16px"}>
									Request Parameters
								</Typography>
								<Box sx={{ marginLeft: "32px", marginTop: "16px", display: "flex", flexDirection: "column", gap: 1 }}>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														country_abbr&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>Country abbreviation (E.g. PH)</Typography>
												</Box>
											</Box>
										}
									/>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														party_id&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>
														The Party ID of the CPO can be retrieved from the email or from the "CPO Details" above.
													</Typography>
												</Box>
											</Box>
										}
									/>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														location_id&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>
														The Location ID can be retrieved from the email or "EVSE Charger List" above.
													</Typography>
												</Box>
											</Box>
										}
									/>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														evse_uid&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>EV Charger Serial Number.</Typography>
												</Box>
											</Box>
										}
									/>
									<Chip
										sx={{ width: "max-content" }}
										label={
											<Box>
												<Box sx={{ display: "flex" }}>
													<Typography
														variant='subtitle2'
														fontWeight={"bold"}>
														connector_id&nbsp;-&nbsp;
													</Typography>
													<Typography variant='subtitle2'>Connector ID</Typography>
												</Box>
											</Box>
										}
									/>
								</Box>
							</Box>
							<Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Typography
										variant='subtitle2'
										fontWeight={"bold"}
										fontSize={"16px"}>
										Sample Request
									</Typography>
									<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
										<Chip
											sx={{ height: "auto", width: "100%", justifyContent: "unset" }}
											label={
												<Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
													<Box
														sx={{
															pt: 3,
															pb: 2,
															px: 5,
															display: "flex",
															alignItems: "center",
															gap: 1,
															alignSelf: "center",
														}}>
														<Chip
															variant='outlined'
															color='secondary'
															label='PATCH'
															sx={{ backgroundColor: "white" }}
														/>
														<Box>
															<Chip
																variant='outlined'
																sx={{ borderRadius: "unset", backgroundColor: "white" }}
																label={
																	<Typography>
																		https://stgocpi-sysnetph.com/ocpi/hub/locations/PH/SOL/186/123412341/1
																	</Typography>
																}
															/>
														</Box>
													</Box>
													<Box sx={{ px: 5, pb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
														<Typography
															variant='subtitle2'
															fontWeight={"bold"}
															fontSize={"16px"}>
															Request Body
														</Typography>
														<Chip
															sx={{ height: "auto", p: 2, justifyContent: "unset", backgroundColor: "white" }}
															variant='outlined'
															label={
																<Typography
																	variant='subtitle2'
																	component={"pre"}>
																	{`{
	"status": "AVAILABLE"  // [AVAILABLE, CHARGING, OFFLINE, RESERVED]
}`}
																</Typography>
															}
														/>
													</Box>
												</Box>
											}
										/>
									</Box>
								</Box>
								<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
									<Typography
										variant='subtitle2'
										fontWeight={"bold"}
										fontSize={"16px"}>
										Sample Response
									</Typography>
									<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
										<Chip
											sx={{ height: "auto", width: "100%", justifyContent: "unset" }}
											label={
												<Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 5, pb: 2 }}>
													<Box sx={{ px: 5, pb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
														<Typography
															variant='subtitle2'
															fontWeight={"bold"}
															fontSize={"16px"}>
															Response Body
														</Typography>
														<Chip
															sx={{
																height: "auto",
																p: 2,
																justifyContent: "unset",
																backgroundColor: "white",
																width: "100%",
															}}
															variant='outlined'
															label={
																<Typography
																	variant='subtitle2'
																	component={"pre"}>
																	{`{
	"status": 200,
	"message": "Success"
}`}
																</Typography>
															}
														/>
													</Box>
												</Box>
											}
										/>
									</Box>
								</Box>
							</Box>
						</AccordionDetails>
					</Accordion>

					{/* <Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
						<Typography
							variant='subtitle2'
							fontWeight={"bold"}
							fontSize={"16px"}>
							Request Body
						</Typography>
						<Box sx={{ marginLeft: "32px", marginTop: "16px" }}>
							<Chip
								sx={{ height: "auto", padding: "16px" }}
								label={
									<Box sx={{ display: "flex", gap: 2, position: "relative" }}>
										<Typography
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}
											fontWeight={"bold"}>
											{HooksBody3}
										</Typography>
										<Button
											sx={{ position: "absolute", fontSize: "8px", fontWeight: "bold", right: 0 }}
											variant='outlined'
											color='inherit'
											size='small'
											onClick={() => handleClick("body")}
											onMouseLeave={() => handleMouseLeave("body")}>
											{BodyBtnText}
										</Button>
									</Box>
								}
							/>
						</Box>
					</Box> */}
				</Box>
			</Box>
		</Box>
	);
};

export default TokenAPIsTab;
