import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import LocationTab from "./LocationTab";
import EVSETab from "./EVSETab";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			style={{ display: "flex", justifyContent: "center" }}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 3, width: "100%" }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const TabsHolder = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label='basic tabs example'>
					<Tab
						label='EV Charger List'
						{...a11yProps(0)}
					/>
					<Tab
						// disabled
						label='Location Sites'
						{...a11yProps(1)}
					/>
				</Tabs>
			</Box>
			<CustomTabPanel
				value={value}
				index={0}>
				<EVSETab />
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={1}>
				<LocationTab />
			</CustomTabPanel>
		</Box>
	);
};

export default TabsHolder;
