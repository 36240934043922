import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Papa from "papaparse";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";

import PDFFile from "../../Assets/files/instructions_v2.pdf";
import UploadLocationList from "../../../Functions/UploadLocationList";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: 500,
	maxWidth: "90vw",
	maxHeight: "95vh",
	bgcolor: "background.paper",
	borderRadius: "8px",
	boxShadow: 24,
};

function createData(
	location_name,
	address,
	lat,
	lng,
	evse_sn,
	kwh,
	connectors,
	connector_format,
	power_type,
	max_voltage,
	max_amperage,
	max_electric_power,
	location_facilities,
	location_parking_types,
	evse_capabilities,
	evse_payment_types
) {
	return {
		location_name,
		address,
		lat,
		lng,
		evse_sn,
		kwh,
		connectors,
		connector_format,
		power_type,
		max_voltage,
		max_amperage,
		max_electric_power,
		location_facilities,
		location_parking_types,
		evse_capabilities,
		evse_payment_types,
	};
}

const ImportModal = (props) => {
	const [cookies, setCookie, removeCookie] = useCookies();
	const [selectedFileName, setSelectedFileName] = useState("");
	const [csvFile, setCSVFile] = useState({});
	const [csvData, setCsvData] = useState([]);
	const [DataList, setDataList] = useState([]);
	const [IsLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (csvData.length > 0) {
			const rows = [];
			csvData?.map((data) => {
				const rowData = createData(
					data.location_name,
					data.address,
					data.lat,
					data.lng,
					data.evse_sn,
					data.kwh,
					data.connectors,
					data.connector_format,
					data.power_type,
					data.max_voltage,
					data.max_amperage,
					data.max_electric_power,
					data.location_facilities,
					data.location_parking_types,
					data.evse_capabilities,
					data.evse_payment_types
				);
				rows.push(rowData);
			});
			setDataList(rows);
		}
	}, [csvData]);

	useEffect(() => {
		console.log(csvFile);
	}, [csvFile]);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file) {
			setCSVFile(file);
			setSelectedFileName(file.name);
			Papa.parse(file, {
				header: true,
				complete: (results) => {
					const filteredData = results.data.filter((row) => row.location_name && row.address);
					setCsvData(filteredData);
					console.log(filteredData);
				},
			});
		}
	};

	const toggleClearData = () => {
		setSelectedFileName("");
		setCSVFile({});
		setCsvData([]);
		setDataList([]);
	};

	const toggleSubmit = () => {
		setIsLoading(true);
		let request = UploadLocationList(cookies, setCookie, csvFile, selectedFileName);
		request.then((res) => {
			if (res.status === 200) {
				props.onSubmitSuccess();
				props.onCloseModal();
			}
			setIsLoading(false);
			console.log(res);
		});
	};

	return (
		<div>
			<Modal
				open={props.onIsImportModalOpen}
				onClose={() => props.onCloseModal()}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box
						sx={{
							backgroundColor: "#0C0354",
							p: 1,
							display: "flex",
							gap: 1,
							alignItems: "center",
						}}>
						<IconButton
							aria-label='close modal'
							onClick={() => props.onCloseModal()}>
							<CloseIcon sx={{ color: "white", fontSize: "1rem!important" }} />
						</IconButton>
						<Typography
							color={"white"}
							variant='subtitle2'
							fontSize={"16px"}
							fontWeight={"bold"}>
							Import EV Charger and Location Site List
						</Typography>
					</Box>

					<Box sx={{ p: 3 }}>
						<Box sx={{ display: "flex", mb: 2, gap: 1 }}>
							<Typography>Follow the CSV data format guide in here:</Typography>
							<Link
								target='_blank'
								rel='noopener noreferrer'
								href={PDFFile}>
								Read Guide
							</Link>
						</Box>
						{selectedFileName === "" && (
							<Button
								component='label'
								sx={{
									border: "solid .25px gray",
									borderRadius: "8px",
									height: "15vh",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									cursor: "pointer",
									backgroundColor: "unset",
									boxShadow: "unset",
								}}>
								<Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}>
									<PostAddIcon sx={{ color: "gray", fontSize: "64px" }} />
									<Typography
										fontSize={"16px"}
										fontWeight={"bold"}
										color={"gray"}
										variant='subtitle2'>
										Import CSV File
									</Typography>
								</Box>
								<input
									type='file'
									hidden
									accept='.csv'
									onChange={handleFileChange}
								/>
							</Button>
						)}

						{selectedFileName && (
							<Typography
								fontSize={"14px"}
								fontWeight={"bold"}
								color={"gray"}
								variant='subtitle2'
								sx={{ mt: 2 }}>
								Selected file: {selectedFileName}
							</Typography>
						)}
						{csvData.length > 0 && (
							<Box sx={{ mt: 2, overflow: "auto", maxHeight: "60vh" }}>
								<Typography
									fontSize={"14px"}
									fontWeight={"bold"}
									color={"gray"}
									variant='subtitle2'>
									CSV Data:
								</Typography>
								<Box sx={{ overflowX: "auto" }}>
									<TableContainer component={Paper}>
										<Table
											sx={{ minWidth: 650 }}
											aria-label='simple table'>
											<TableHead>
												<TableRow>
													<TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>Location Name</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='center'>
														Address
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Latitude
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Longitude
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														EVSE Serial Number
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														KWH
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Connectors
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Connector Format
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Power Type
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Max Voltage
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Max Amperage
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Max Electric Power
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Location Facilities
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														Location Parking Types
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														EVSE Capabilities
													</TableCell>
													<TableCell
														sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
														align='right'>
														EVSE Payment Types
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{DataList.map((data) => (
													<TableRow
														key={data.name}
														sx={{ "&:last-child td, &:last-child th": { border: 0 }, whiteSpace: "nowrap" }}>
														<TableCell
															component='th'
															scope='data'>
															{data.location_name}
														</TableCell>
														<TableCell>{data.address}</TableCell>
														<TableCell align='right'>{data.lat}</TableCell>
														<TableCell align='right'>{data.lng}</TableCell>
														<TableCell align='right'>{data.evse_sn}</TableCell>
														<TableCell align='right'>{data.kwh}</TableCell>
														<TableCell align='right'>{data.connectors}</TableCell>
														<TableCell align='right'>{data.connector_format}</TableCell>
														<TableCell align='right'>{data.power_type}</TableCell>
														<TableCell align='right'>{data.max_voltage}</TableCell>
														<TableCell align='right'>{data.max_amperage}</TableCell>
														<TableCell align='right'>{data.max_electric_power}</TableCell>
														<TableCell align='right'>{data.location_facilities}</TableCell>
														<TableCell align='right'>{data.location_parking_types}</TableCell>
														<TableCell align='right'>{data.evse_capabilities}</TableCell>
														<TableCell align='right'>{data.evse_payment_types}</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
							</Box>
						)}
					</Box>
					{selectedFileName !== "" && (
						<Box
							sx={{
								px: 3,
								pb: 2,
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<Button
								disabled={IsLoading}
								color='error'
								onClick={() => toggleClearData()}>
								Clear Data
							</Button>
							<Button
								disabled={IsLoading}
								variant='contained'
								color='success'
								onClick={() => toggleSubmit()}>
								{IsLoading ? (
									<CircularProgress
										size={"1.5rem"}
										color='inherit'
									/>
								) : (
									"Submit"
								)}
							</Button>
						</Box>
					)}
				</Box>
			</Modal>
		</div>
	);
};

export default ImportModal;
